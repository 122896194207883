/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { AbstractRequestModelConverter, NoopModelConverter } from '@chroma-x/frontend/core/api-integration';

import { ResponsiblesRequestModel } from '@telmimo/frontend/domain/okr/domain-model';

import { ResponsiblesRequestDto } from '../../../../../dto';

export class ResponsiblesRequestRequestModelConverter extends AbstractRequestModelConverter<
	ResponsiblesRequestModel,
	ResponsiblesRequestDto
> {
	public toDto(model: ResponsiblesRequestModel): ResponsiblesRequestDto {
		return {
			responsibles: new NoopModelConverter().toArrayOfDto(model.responsibles)
		};
	}
}
