import process from 'process';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AuthenticationRequiredError, NotFoundError, ValidationError } from '@chroma-x/common/core/error';
import { L10n, Locale } from '@chroma-x/common/core/l10n';
import { ConsoleLogger, DistributingLogger, LogLevel } from '@chroma-x/frontend/core/logger';
import { EnvProvider } from '@chroma-x/frontend/core/react-environment';
import { ErrorHandler } from '@chroma-x/frontend/core/react-error-handler';
import { FeatureFlagProvider } from '@chroma-x/frontend/core/react-feature-flag-provider';
import { L10nProvider } from '@chroma-x/frontend/core/react-l10n';
import { LoggerProvider } from '@chroma-x/frontend/core/react-logger';
import { ServiceManagerProvider } from '@chroma-x/frontend/core/react-service-provider';
import { sentryConfig, SentryLogger, TagValue } from '@chroma-x/frontend/core/sentry-integration';
import { unleashProxyClientConfig } from '@chroma-x/frontend/core/unleash-integration';

import { AuthRouter } from './app/router/auth-router';
import { environment } from './environments/environment';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

try {
	// Set up locales to be provided
	const locales = [
		// Option 1: use straight imported JSON modules
		// new Locale('aa', [appLiteralsEn]),
		// Option 2: use URLs to load the literals from
		//   Usually created from the JSON modules by running `nx bundle-literals frontend-customer-app` and a proper literal.config.json
		new Locale('de', ['/literal-bundles/literal-bundle-de.json']),
		new Locale('en', ['/literal-bundles/literal-bundle-en.json'])
	];

	// Set up logger instances to be provided
	const distributingLogger = new DistributingLogger();
	distributingLogger.addLogger(new ConsoleLogger(true, undefined, undefined));
	if (process.env.NX_PUBLIC_SENTRY_ENABLED === 'true') {
		const sentryTracePropagationTargets = [
			/^\//,
			/^localhost/,
			/^(https:\/\/)api\.?\w*\.starter\.chroma-x\.dev/
		];
		distributingLogger.addLogger(new SentryLogger(
			sentryConfig(sentryTracePropagationTargets),
			true,
			(error, logLevel) => {
				return !(
					error instanceof ValidationError
					|| error instanceof NotFoundError
					|| error instanceof AuthenticationRequiredError
					|| logLevel < LogLevel.WARN
				);
			},
			undefined,
			undefined,
			() => {
				return new Map<string, TagValue>([
					['client.locale', L10n.selectedLocale()]
					// ['stomp.enabled', process.env['NX_STOMP_ENABLED'] === 'true'],
					// ['stomp.open', WebsocketInstrument.get().isOpened()],
					// ['installationPartner.realm', oauthRealm],
					// ['installationPartner.path', installationPartnerPath],
					// ['installationPartner.clientId', installationPartnerClientId]
				]);
			}
		));
	}

	const ErrorView = (error: Error) => {
		return (<pre>Something went wrong with message: {error.message}</pre>);
	};

	root.render(
		<StrictMode>
			<LoggerProvider logger={distributingLogger}>
				<ErrorHandler errorComponent={ErrorView}>
					<EnvProvider env={environment}>
						<L10nProvider locales={locales} fallbackLocaleIdentifier="en">
							<FeatureFlagProvider config={unleashProxyClientConfig()}>
								<BrowserRouter>
									<ServiceManagerProvider>
										<AuthRouter />
									</ServiceManagerProvider>
								</BrowserRouter>
							</FeatureFlagProvider>
						</L10nProvider>
					</EnvProvider>
				</ErrorHandler>
			</LoggerProvider>
		</StrictMode>
	);

} catch (e) {
	root.render(<pre>Something went wrong</pre>);
}
