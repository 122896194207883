/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ApiError } from '@chroma-x/common/core/error';
import { guardProbably } from '@chroma-x/common/core/util';

import { KeyResultBasedProgressObjectiveModel, ObjectiveProgressMode } from '@telmimo/frontend/domain/okr/domain-model';

import { BaseObjectiveResponseModelConverter } from './base-objective-response-model-converter';
import { KeyResultBasedProgressObjectiveDto } from '../../../../dto';

export class KeyResultBasedProgressObjectiveResponseModelConverter extends BaseObjectiveResponseModelConverter {
	public toModel(dto: KeyResultBasedProgressObjectiveDto): KeyResultBasedProgressObjectiveModel {
		return {
			...super.toModel(dto),
			progressMode: guardProbably(
				dto.progressMode,
				new ApiError("Expected property 'progressMode' of 'KeyResultBasedProgressObjectiveDto' missing.")
			) as ObjectiveProgressMode.KEY_RESULTS,
			progress: guardProbably(
				dto.progress,
				new ApiError("Expected property 'progress' of 'KeyResultBasedProgressObjectiveDto' missing.")
			) satisfies number
		};
	}
}
