import { isNode } from 'browser-or-node';

import {
	ApiError,
	AppError,
	AuthenticationRequiredError,
	NetworkError,
	TimeoutError
} from '@chroma-x/common/core/error';
import { JsonTransportValue } from '@chroma-x/common/core/json';
import { L10n } from '@chroma-x/common/core/l10n';
import { Nullable, Timeout } from '@chroma-x/common/core/util';

import { HttpMethod } from './http-method';
import { HttpRequestHeader } from './http-request-header';
import { RestResponse } from './json-rest-response';
import { DefaultErrorResponseHandler } from '../error/default-error-response-handler';
import { ErrorResponseHandler } from '../error/error-response-handler';

export class JsonRestRequest {

	private static errorResponseHandler: ErrorResponseHandler = new DefaultErrorResponseHandler();

	private readonly abortController: AbortController = new AbortController();
	private lastRequest: Nullable<Request> = null;
	private lastResponse: Nullable<Response> = null;
	private requestHeaders: Set<HttpRequestHeader> = new Set();

	/**
	 * Sets the error response handler.
	 * @param errorResponseHandler The error response handler to set.
	 */
	public static setErrorResponseHandler(errorResponseHandler: ErrorResponseHandler): void {
		this.errorResponseHandler = errorResponseHandler;
	}

	/**
	 * @returns The last executed request object
	 */
	public getLastRequest(): Nullable<Request> {
		return this.lastRequest;
	}

	/**
	 * @returns The response for the last executed request
	 */
	public getLastResponse(): Nullable<Response> {
		return this.lastResponse;
	}

	/**
	 * Pre-request processing.
	 * @param request The request to process.
	 * @returns The processed request.
	 * @example
	 * override protected preRequest(request: Request): Request {
	 * 	console.debug(request.headers);
	 * 	return request;
	 * }
	 */
	protected preRequest(request: Request): Request {
		return request;
	}

	/**
	 * Post-request processing.
	 * @param response The response to process.
	 * @returns The processed response.
	 * @example
	 * override protected postRequest(response: Response): Response {
	 * 	const sessionCookie = response.headers.getSetCookie();
	 * 	return response;
	 * }
	 */
	protected postRequest(response: Response): Response {
		return response;
	}

	/**
	 * Perform an OPTIONS request.
	 * @param uri The URI to send the request to.
	 * @returns A promise with the response.
	 * @example
	 * const response = await jsonRestRequest.options('https://api.example.com');
	 */
	public async options(uri: string): Promise<RestResponse<undefined>> {
		let request = new Request(
			uri,
			this.buildRequestOptions(HttpMethod.OPTIONS)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		this.handleResponse(response.status, {});
		return {
			body: undefined,
			headers: this.unwrapHeaders(response)
		};
	}

	/**
	 * Perform a HEAD request.
	 * @param uri The URI to send the request to.
	 * @returns A promise with the response.
	 * @example
	 * const response = await jsonRestRequest.head('https://api.example.com');
	 */
	public async head(uri: string): Promise<RestResponse<undefined>> {
		let request = new Request(
			uri,
			this.buildRequestOptions(HttpMethod.HEAD)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		this.handleResponse(response.status, {});
		return {
			body: undefined,
			headers: this.unwrapHeaders(response)
		};
	}

	/**
	 * Perform a GET request.
	 * @param uri The URI to send the request to.
	 * @returns A promise with the response.
	 * @example
	 * const response = await jsonRestRequest.get('https://api.example.com/data');
	 */
	public async get(uri: string): Promise<RestResponse<Array<JsonTransportValue> | JsonTransportValue | null>> {
		let request = new Request(
			uri,
			this.buildRequestOptions(HttpMethod.GET)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	/**
	 * Perform a QUERY request.
	 * @param uri The URI to send the request to.
	 * @param data The data to send in the request body.
	 * @returns A promise with the response.
	 * @example
	 * const response = await jsonRestRequest.query('https://api.example.com/data', jsonData);
	 */
	public async query(uri: string, data: JsonTransportValue): Promise<RestResponse<Nullable<JsonTransportValue>>> {
		let request = new Request(
			uri,
			this.buildRequestOptionsWithBody(HttpMethod.QUERY, data)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	/**
	 * Perform a PUT request.
	 * @param uri The URI to send the request to.
	 * @param data The data to send in the request body.
	 * @returns A promise with the response.
	 * @example
	 * const response = await jsonRestRequest.put('https://api.example.com/data', jsonData);
	 */
	public async put(uri: string, data: JsonTransportValue): Promise<RestResponse<Nullable<JsonTransportValue>>> {
		let request = new Request(
			uri,
			this.buildRequestOptionsWithBody(HttpMethod.PUT, data)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	/**
	 * Perform a PATCH request.
	 * @param uri The URI to send the request to.
	 * @param data The data to be sent in the request body.
	 * @returns A promise with the response.
	 * @example
	 * const response = await jsonRestRequest.patch('https://api.example.com/data', jsonData);
	 */
	public async patch(uri: string, data: JsonTransportValue): Promise<RestResponse<Nullable<JsonTransportValue>>> {
		let request = new Request(
			uri,
			this.buildRequestOptionsWithBody(HttpMethod.PATCH, data)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	/**
	 * Perform a POST request.
	 * @param uri The URI to send the request to.
	 * @param data The data to send in the request body.
	 * @returns A promise with the response.
	 * @example
	 * const response = await jsonRestRequest.post('https://api.example.com/data', jsonData);
	 */
	public async post(uri: string, data: JsonTransportValue): Promise<RestResponse<JsonTransportValue>> {
		let request = new Request(
			uri,
			this.buildRequestOptionsWithBody(HttpMethod.POST, data)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	/**
	 * Perform a DELETE request.
	 * @param uri The URI to send the request to.
	 * @returns A promise with the response.
	 */
	public async delete(uri: string): Promise<RestResponse<Nullable<JsonTransportValue>>> {
		let request = new Request(
			uri,
			this.buildRequestOptions(HttpMethod.DELETE)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	/**
	 * Aborts the request by canceling the associated AbortController signal.
	 */
	public abort(): void {
		this.abortController.abort();
	}

	private buildRequestOptions(method: HttpMethod): RequestInit {
		const additionalHeaders: Record<string, string> = {};
		this.requestHeaders.forEach((requestHeader) => {
			additionalHeaders[requestHeader.name] = requestHeader.value ?? '';
		});
		const requestHeaders: Record<string, string> = {
			...additionalHeaders,
			'Accept-Language': L10n.effectiveLocale() ?? '',
			'X-Local-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		};
		return {
			signal: this.abortController.signal,
			method: String(method),
			cache: 'no-cache',
			headers: requestHeaders
		};
	}

	private buildRequestOptionsWithBody(method: HttpMethod, body: JsonTransportValue): RequestInit {
		return {
			...this.buildRequestOptions(method),
			body: JSON.stringify(body)
		};
	}

	/**
	 * Adds a header to the request.
	 * @param name The name of the header.
	 * @param value The value of the header.
	 * @param exclusive Whether the request header should be exclusive and therefor overrides existing headers with the same name
	 */
	public addRequestHeader(name: string, value: string, exclusive = true): void {
		const requestHeaders = Array.from(this.requestHeaders.values());
		if (exclusive) {
			requestHeaders.filter((requestHeader) => {
				return requestHeader.name !== name;
			});
		}
		requestHeaders.push({ name, value });
		this.requestHeaders = new Set(requestHeaders);
	}

	private async perform(request: Request, timeout = 20000): Promise<Response> {
		this.lastRequest = request;
		this.lastResponse = null;
		const response = await Timeout.wrap(fetch(request), timeout, new TimeoutError('Request timeout'), () => {
			this.abortController.abort();
		});
		if (isNode) {
			// Do not clone the response object if in node environment due to a "feature" (which causes issues in our use cases) with
			// node-fetch; see: https://github.com/node-fetch/node-fetch/issues/396 for deeper insights
			const nodeResponse = new Response(response.body, {
				headers: response.headers,
				status: response.status
			});
			this.lastResponse = nodeResponse;
			return nodeResponse;
		}
		this.lastResponse = response;
		return response.clone();
	}

	private async unwrapResponse(response: Response): Promise<Nullable<JsonTransportValue>> {
		if (response === null) {
			return null;
		}
		try {
			return await response.json();
		} catch (e) {
			throw new ApiError('Parse response failed with message: ' + (e as AppError).message);
		}
	}

	private unwrapHeaders(response: Response): Map<string, string> {
		const headers = new Map();
		if (response === null || response.headers === null) {
			return headers;
		}
		response.headers.forEach((value, key) => {
			headers.set(key.toLowerCase(), value);
		});
		return headers;
	}

	private preProcessResponse(responseStatus: number): void {
		if (responseStatus < 200) {
			throw new NetworkError('Request failed');
		}
		if (responseStatus === 401) {
			throw new AuthenticationRequiredError('Authentication required');
		}
	}

	private handleResponse(responseStatus: number, responseBody: Nullable<JsonTransportValue>): Nullable<JsonTransportValue> {
		if (responseStatus >= 200 && responseStatus < 300) {
			return responseBody;
		}

		throw JsonRestRequest.errorResponseHandler.handleErrorResponse(responseStatus, responseBody);
	}

}
