/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { AbstractRequestModelConverter } from '@chroma-x/frontend/core/api-integration';

import { ProgressRequestModel } from '@telmimo/frontend/domain/okr/domain-model';

import { ProgressRequestDto } from '../../../../../dto';

export class ProgressRequestRequestModelConverter extends AbstractRequestModelConverter<ProgressRequestModel, ProgressRequestDto> {
	public toDto(model: ProgressRequestModel): ProgressRequestDto {
		return {
			progress: model.progress satisfies number
		};
	}
}
