import { Fragment } from 'react';

import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { NotFoundError } from '@chroma-x/common/core/error';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';

import { IdentityKey } from '@telmimo/frontend/domain/okr/domain-model';
import { useObjectiveService } from '@telmimo/frontend/domain/okr/react-service-provider';

export type ObjectiveRemoveFollowerFormProps = {
	id: ModelPrimaryKey
};

export function ObjectiveRemoveFollowerForm(props: ObjectiveRemoveFollowerFormProps) {

	const { id } = props;

	const l10n = useL10n();
	const service = useObjectiveService();
	const objective = service.query.queryEntity(id)
		.getOrThrow(new NotFoundError(l10n.translate('okr.reactUiDomain.error.objectiveNotFound')));

	const handleRemoveFollower = (follower: IdentityKey) => {
		void service.command.removeFollower(id, follower);
	};

	const renderFollowers = () => {
		return objective.followers.map((follower) => {
			return (
				<Fragment key={follower}>
					<button onClick={() => handleRemoveFollower(follower)}>Remove follower {follower}</button>
					<br />
				</Fragment>
			);
		});
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<fieldset>
				<legend>Remove follower for objective with ID {id}</legend>
				{renderFollowers()}
			</fieldset>
		</form>
	);
}
