import { useState } from 'react';

import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { NotFoundError } from '@chroma-x/common/core/error';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';

import { useObjectiveService } from '@telmimo/frontend/domain/okr/react-service-provider';

export type ObjectiveUpdateFormProps = {
	id: ModelPrimaryKey
};

export function ObjectiveUpdateForm(props: ObjectiveUpdateFormProps) {

	const { id } = props;

	const l10n = useL10n();
	const service = useObjectiveService();
	const objective = service.query.queryEntity(id)
		.getOrThrow(new NotFoundError(l10n.translate('okr.reactUiDomain.error.objectiveNotFound')));

	const [title, setTitle] = useState<string>(objective.title);

	const handleUpdate = () => {
		if (title) {
			void service.command.mutate(id, {
				progressMode: objective.progressMode,
				title
			});
		}
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<fieldset>
				<legend>Mutate objective with ID {id}</legend>
				<label>
					Title
					<input type="text" name="title" value={title} onChange={(e) => {
						setTitle(e.currentTarget.value);
					}} />
				</label>
				<button onClick={handleUpdate}>
					Update
				</button>
			</fieldset>
		</form>
	);
}
