import { Nullable } from '@chroma-x/common/core/util';

import { JsonRestRequest } from './json-rest-request';

export class BearerTokenAuthedJsonRestRequest extends JsonRestRequest {

	private readonly accessToken: Nullable<string> = null;

	/**
	 * Constructor for JwtAuthedJsonRestRequest.
	 * @param accessToken The JSON web token.
	 */
	public constructor(accessToken: Nullable<string> = null) {
		super();
		this.accessToken = accessToken;
	}

	/**
	 * Pre-request processing.
	 * @param request The request to process.
	 * @returns The processed request.
	 * @example
	 * override protected preRequest(request: Request): Request {
	 * 	console.debug(request.headers);
	 * 	return request;
	 * }
	 */
	protected override preRequest(request: Request): Request {
		if (this.accessToken !== null) {
			request.headers.set('Authorization', 'Bearer ' + this.accessToken);
		}
		return request;
	}

}
