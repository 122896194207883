/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { AbstractRequestModelConverter } from '@chroma-x/frontend/core/api-integration';

import { ObjectiveMutateModel, ObjectiveProgressMode } from '@telmimo/frontend/domain/okr/domain-model';

import { KeyResultBasedProgressObjectiveMutateRequestModelConverter } from './key-result-based-progress-objective-mutate-request-model-converter';
import { ManualProgressObjectiveMutateRequestModelConverter } from './manual-progress-objective-mutate-request-model-converter';
import { ObjectiveMutateDto } from '../../../../../dto';

export class ObjectiveMutateRequestModelConverter extends AbstractRequestModelConverter<ObjectiveMutateModel, ObjectiveMutateDto> {
	public toDto(model: ObjectiveMutateModel): ObjectiveMutateDto {
		switch (model.progressMode) {
			case ObjectiveProgressMode.MANUAL:
				return new ManualProgressObjectiveMutateRequestModelConverter().toDto(model);
			case ObjectiveProgressMode.KEY_RESULTS:
				return new KeyResultBasedProgressObjectiveMutateRequestModelConverter().toDto(model);
		}
	}
}
