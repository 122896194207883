import { SortDirection } from '@chroma-x/common/core/api-integration';
import { useAuth } from '@chroma-x/frontend/core/react-auth-provider';
import { withUiErrorHandler } from '@chroma-x/frontend/core/react-ui-error-handler';

import { InitiativeHistoryServiceProvider } from '@telmimo/frontend/domain/okr/react-service-provider';

import { InitiativeHistory } from '../components/initiative-history/initiative-history';

const ServiceProviderWithUiErrorHandler = withUiErrorHandler(InitiativeHistoryServiceProvider);

export function InitiativeHistoryView() {

	const auth = useAuth();

	const handleLogout = () => {
		auth?.unauthenticate();
	};

	return (
		<div>
			<pre>Users</pre>
			<hr />
			<ServiceProviderWithUiErrorHandler
				sortCriteria={[
					{ property: 'status', direction: SortDirection.ASCENDING }
				]}
			>
				<InitiativeHistory />
			</ServiceProviderWithUiErrorHandler>
			<hr />
			<button onClick={handleLogout}>Logout</button>
		</div>
	);
}
