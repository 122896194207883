import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { isTimestamp } from '@chroma-x/common/core/data-type';
import { NotFoundError } from '@chroma-x/common/core/error';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';

import { useInitiativeHistoryService } from '@telmimo/frontend/domain/okr/react-service-provider';

export type InitiativeHistoryEntryProps = {
	entryId: ModelPrimaryKey
};

export function InitiativeHistoryEntry(props: InitiativeHistoryEntryProps) {

	const { entryId } = props;

	const l10n = useL10n();
	const service = useInitiativeHistoryService();
	const entry = service.query.queryEntity(entryId)
		.getOrThrow(new NotFoundError(l10n.translate('okr.reactUiDomain.error.initiativeEntryNotFound')));

	const renderProperties = () => {
		const rows = Object.entries(entry).map(([key, value]) => {
			const displayValue = isTimestamp(value) ? l10n.formatTimestampDateTime(value) : value;
			return (
				<tr key={key}>
					<td>{key}</td>
					<td>{displayValue}</td>
				</tr>
			);
		});
		return (
			<tbody>
				{rows}
			</tbody>
		);
	};

	return (
		<table>
			<thead>
				<tr>
					<th>Property</th>
					<th>Value</th>
				</tr>
			</thead>
			{renderProperties()}
		</table>
	);
}
