import { createContext, useContext } from 'react';
import { useStore } from 'zustand';

import { AppError } from '@chroma-x/common/core/error';
import { Nullable } from '@chroma-x/common/core/util';
import { FetchError } from '@chroma-x/frontend/core/business-error';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import {
	CollectionServiceProviderProps,
	FetchMode,
	useFetchCollectionFailedHandler,
	useFetchInit,
	useFetchSuspense
} from '@chroma-x/frontend/core/react-service-provider';

import { ObjectiveModel } from '@telmimo/frontend/domain/okr/domain-model';
import { objectiveService, ObjectiveService } from '@telmimo/frontend/domain/okr/service';

const serviceContext = createContext<Nullable<ObjectiveService>>(null);

export const useObjectiveService = () => {
	const service = useContext(serviceContext);
	if (service === null) {
		throw new AppError('No service provided');
	}
	return service;
};

export function ObjectiveServiceProvider(props: CollectionServiceProviderProps<ObjectiveModel>) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		sortCriteria,
		filterCriteria
	} = props;

	const l10n = useL10n();

	const service = useStore(objectiveService);

	useFetchCollectionFailedHandler(
		service, new FetchError(l10n.translate('okr.reactServiceProvider.error.objectiveCollectionFetchMessage'))
	);

	const currentSortCriteria = service.meta.querySortCriteria();
	const effectiveSortCriteria = currentSortCriteria ?? sortCriteria;
	const currentFilterCriteria = service.meta.queryFilterCriteria();
	const effectiveFilterCriteria = currentFilterCriteria ?? filterCriteria;
	useFetchInit(fetchMode, () => service.command.fetchCollection(effectiveSortCriteria, effectiveFilterCriteria, forceFetch));

	return useFetchSuspense(service.meta.queryFetchStatus(), suspense, pendingComponent).getOrDefault(
		<serviceContext.Provider value={service}>
			{props.children}
		</serviceContext.Provider>
	);
}
