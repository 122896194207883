import { Route } from 'react-router-dom';

import { useSentryRoutes } from '@chroma-x/frontend/core/react-sentry-integration';
import { buildRouteUrl } from '@chroma-x/frontend/core/routing';

import {
	InitiativeHistoryEntryView,
	InitiativeHistoryView,
	ObjectiveView,
	ObjectiveCollectionView,
	RoutePath as OkrRoutePath
} from '@telmimo/frontend/domain/okr/react-ui-domain';

import { RoutePath } from './routes';
import { RootView } from '../views/root-view';

export function Router() {

	const Routes = useSentryRoutes();

	return (
		<Routes>
			<Route path={buildRouteUrl(RoutePath.ROOT)} element={<RootView />} />
			<Route path={buildRouteUrl(OkrRoutePath.OKR_INITIATIVE_HISTORY)} element={<InitiativeHistoryView />} />
			<Route path={buildRouteUrl(OkrRoutePath.OKR_INITIATIVE_HISTORY_ENTRY)} element={<InitiativeHistoryEntryView />} />
			<Route path={buildRouteUrl(OkrRoutePath.OKR_OBJECTIVES)} element={<ObjectiveCollectionView />} />
			<Route path={buildRouteUrl(OkrRoutePath.OKR_OBJECTIVE)} element={<ObjectiveView />} />
			<Route path="*" element={<RootView />} />
		</Routes>
	);

}
