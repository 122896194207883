import {
	CollectionPaginationResponse,
	DeleteResponseDto,
	FilterCriteria,
	HypermediaEntityResponse,
	ModelPrimaryKey,
	PaginatedCollectionResponse,
	SortCriteria
} from '@chroma-x/common/core/api-integration';

import { ObjectiveServiceApiClientFactory } from '@telmimo/frontend/domain/okr/api-integration';
import {
	IdentityKey,
	ObjectiveCreateModel,
	ObjectiveModel,
	ObjectiveMutateModel
} from '@telmimo/frontend/domain/okr/domain-model';

export abstract class ObjectiveUseCasesBase {

	public async headCollection(filter?: FilterCriteria<ObjectiveModel>): Promise<CollectionPaginationResponse> {
		return ObjectiveServiceApiClientFactory.create().headPage(1, undefined, filter);
	}

	public async fetchCollection(
		sort?: SortCriteria<ObjectiveModel>,
		filter?: FilterCriteria<ObjectiveModel>
	): Promise<PaginatedCollectionResponse<ObjectiveModel>> {
		return ObjectiveServiceApiClientFactory.create().fetchPage(1, undefined, sort, filter);
	}

	public async fetchPage(
		page = 1,
		sort?: SortCriteria<ObjectiveModel>,
		filter?: FilterCriteria<ObjectiveModel>
	): Promise<PaginatedCollectionResponse<ObjectiveModel>> {
		return ObjectiveServiceApiClientFactory.create().fetchPage(page, undefined, sort, filter);
	}

	public async fetchEntity(id: ModelPrimaryKey): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		return ObjectiveServiceApiClientFactory.create().fetch(id);
	}

	public async searchEntity(filter: FilterCriteria<ObjectiveModel>): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		return ObjectiveServiceApiClientFactory.create().search(filter);
	}

	public async mutate(id: ModelPrimaryKey, mutation: ObjectiveMutateModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		return ObjectiveServiceApiClientFactory.create().mutate(id, mutation);
	}

	public async create(model: ObjectiveCreateModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		return ObjectiveServiceApiClientFactory.create().create(model);
	}

	public async delete(id: ModelPrimaryKey): Promise<DeleteResponseDto> {
		return ObjectiveServiceApiClientFactory.create().delete(id);
	}

	public async addFollower(id: ModelPrimaryKey, follower: IdentityKey): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		return await ObjectiveServiceApiClientFactory.create().addFollower(id, { follower });
	}

	public async removeFollower(id: ModelPrimaryKey, follower: IdentityKey): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		return await ObjectiveServiceApiClientFactory.create().removeFollower(id, { follower });
	}

	public async setFollowers(id: ModelPrimaryKey, followers: Array<IdentityKey>): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		return await ObjectiveServiceApiClientFactory.create().setFollowers(id, { followers });
	}

}
