/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { AbstractRequestModelConverter } from '@chroma-x/frontend/core/api-integration';

import { ObjectiveStatus, StatusRequestModel } from '@telmimo/frontend/domain/okr/domain-model';

import { StatusRequestDto } from '../../../../../dto';

export class StatusRequestRequestModelConverter extends AbstractRequestModelConverter<StatusRequestModel, StatusRequestDto> {
	public toDto(model: StatusRequestModel): StatusRequestDto {
		return {
			status: model.status satisfies ObjectiveStatus
		};
	}
}
